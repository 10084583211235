import styled from "@emotion/styled";
import React from "react";

export const Footer = () => (
  <StyledFooter>
    &copy; {new Date().getFullYear()} PM Catering, ABN: 17 126 046 302
  </StyledFooter>
);

const StyledFooter = styled.footer`
  text-align: center;
  font-size: 12px;
  max-width: 840px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  border-top: 1px solid black;
  padding: 20px;
  margin: 0 auto;

  @media (max-width: 880px) {
    margin: 0 20px;
  }
`;
