import styled from "@emotion/styled";

export const Body = styled.div`
  margin: 20px auto;
  max-width: 840px;
  padding: 0 20px;
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
  min-height: 540px;

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 14px;
  }

  p {
    margin-bottom: 14px;
  }

  strong {
    font-weight: bold;
  }

  @media (max-width: 880px) {
    margin: 20px;
  }
`;
