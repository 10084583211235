import { ClassNames, Global } from "@emotion/core";
import styled from "@emotion/styled";
import emotionReset from "emotion-reset";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Herr+Von+Muellerhoff|Josefin+Sans"
          rel="stylesheet"
        />
      </Helmet>
      <Global styles={emotionReset} />
      <Helmet title={data.site.siteMetadata.title} />
      <HeaderWrapper>
        <Link style={{ textDecoration: "none" }} to="/">
          <Title>P M Catering</Title>
        </Link>
      </HeaderWrapper>
      <ClassNames>
        {({ css }) => {
          const linkStyle = css`
            color: #777;
            text-decoration: none;
            padding: 5px;

            &:hover {
              text-decoration: underline;
            }
          `;
          return (
            <Nav>
              <Link className={linkStyle} to="/">
                Home
              </Link>
              <Link className={linkStyle} to="/finger-food">
                Finger Food
              </Link>
              <Link className={linkStyle} to="/entrees">
                Entrees
              </Link>
              <Link className={linkStyle} to="/mains">
                Mains
              </Link>
              <Link className={linkStyle} to="/desserts">
                Desserts
              </Link>
              <Link className={linkStyle} to="/sandwiches">
                Sandwiches
              </Link>
              <Link className={linkStyle} to="/events">
                Events
              </Link>
              <Link className={linkStyle} to="/menu">
                Menu
              </Link>
              <Link className={linkStyle} to="/price-list">
                Price List
              </Link>
              <Link className={linkStyle} to="/contact">
                Contact
              </Link>
            </Nav>
          );
        }}
      </ClassNames>
    </>
  );
};

const HeaderWrapper = styled.header`
  text-align: center;
`;

const Title = styled.h1`
  font-family: "Herr Von Muellerhoff", cursive;
  font-size: 72px;
  line-height: 104px;
  font-weight: 100;
  color: #00099f;
  padding: 20px;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-top: 1px solid rgba(0, 0, 0, 0.5);

  max-width: 840px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  padding: 5px 20px;
  margin: 0 auto;

  @media (max-width: 880px) {
    margin: 0 20px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;
