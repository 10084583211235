import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Body } from "../components/Body";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import Img from "gatsby-image";
import styled from "@emotion/styled";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "scallops.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Header />
      <Body>
        <ImageWrapper>
          <Img
            style={{
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.29)",
            }}
            fluid={data.mainImage.childImageSharp.fluid}
          />
        </ImageWrapper>
        <p>
          Philippe Manouvrier has been in the business for many years. Moving
          from European culinary school to fine dining restaurants and corporate
          dining PM Catering was founded in 1999. We are motivated by both our
          passion for food and commitment to making your event a memorable
          occasion. Our use of fresh produce and innovative recipes makes our
          catering a unique experience, distinguishing us from the rest. Your
          needs are of the upmost importance to us and will be reflected in your
          final menu.
        </p>
        <p>
          Our exclusive catering service will come to your home, office, or
          venue of choice across Sydney to create a hassle-free and enjoyable
          experience for you and your guests.
        </p>
        <p>
          Philippe’s no-fuss attitude and legendary flexibility to special
          requests, dietary requirements and budgets will leave you satisfied
          and confident you have made the right choice. No extra charges or
          inflated prices, even for weddings.
        </p>
        <p>
          Our dedication to the perfect event does not stop with the food. Our
          professional service incorporates all aspects of your event from set
          up and waiters to equipment hire and cleaning. You will never know we
          were ever in your kitchen.
        </p>
        <p>Many of our new clients were once guests at our previous events.</p>
      </Body>
      <Footer />
    </>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  margin: 0 auto 20px;
`;

export default IndexPage;
